.wrapper {
  position: absolute;
  top: 0;
  z-index: 2000;
}

.container {
  display: flex;
  gap: 10px;

  & > * {
    font-size: 10px;
    color: #999;
  }

  & button:hover {
    color: black;
  }
}

@media (--sm) {
  .mapButtons {
    display: none;
  }
}

.title {
  font-weight: 600;
}

