@import './config/index.css';
@import './config/easing.css';

/*-------------------------------------------
// Booking module
//-----------------------------------------*/
.booking-module-modern {
  display: flex;
  position: relative;
  background: #999;
  overflow: visible;
  flex-direction: column;

  & .booking-module-modern-form {
    width: 100%;
    pointer-events: none;
    filter: drop-shadow(var(--box-shadow));
    z-index: 40;
  }

  @media (--lg) {
    min-height: 609px;
    height: calc(100vh - 100px);
    padding: 40px;
    flex-direction: column-reverse;
  }

  /* & .gradient-overlay { */
  /*   position: absolute; */
  /*   bottom: 0; */
  /*   left: 0; */
  /*   width: 100%; */
  /*   height: 200px; */
  /*   background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(0 0 0 / 56%) 100%); */
  /*   z-index: 30; */
  /*   pointer-events: none; */
  /* } */

  /*-------------------------------------------
  // Booking cover
  //-----------------------------------------*/
  & .cover-widget {
    background-color: black;
    background-size: cover;
    background-position: center;
    & video {
      position: absolute;
      z-index: 1;
      object-fit: cover;
    }

    @media (--xxl) {
      width: 100%;
    }

    @media (--lg) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 20;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s var(--easeInOutCirc), visibility 0s 0.3s;
    }

    & .cover-container {
      padding: 30px 20px 30px 40px;

      @media (--lg) {
        padding: 80px 20px 80px 40px;
        align-items: initial;
        justify-content: initial;
      }
      @media (--lg) {
        padding: 80px 60px 80px 60px;
      }
      @media (min-width: 1600px) {
        padding: 120px 170px;
      }
      @media (min-width: 1920px) {
        padding: 120px 195px;
      }
    }

    & .cover-content {
      max-width: none;
      position: relative;
      z-index: 2;
      & p {
        width: 100%;
        font-size: 16px;
        line-height: 22px;
        @media (--md) {
          font-size: 18px;
          line-height: 24px;
        }
        @media (--lg) {
          width: calc(100% - 624px - 15px);
        }
        @media (--xl) {
          font-size: 20px;
          line-height: 26px;
        }
        @media (min-width: 1600px) {
          width: calc(100% - 624px - 40px - 20px);  
        }
      }
      & h2 {
        @media (--lg) {
          font-size: 60px;
          line-height: 70px;
          max-width: 960px;
        }
        @media (--xxl) {
          
        }
        
        @media (min-width: 1600px) {
          font-size: 72px;
          line-height: 80px;
          
        }
      }
    }
  }
  & .cover-widget.right {
    background-position: 100% top;
  }
  & .cover-widget.left {
    background-position: 0% top;
  }

  /*-------------------------------------------
  // Map widget
  //-----------------------------------------*/
  & #map-modern {
    display: none;

    @media (--lg) {
      display: block;
    }
  }

  /*-------------------------------------------
  // Cover / map transition
  //-----------------------------------------*/
  & #map-modern #map {
    transform: scale(1);
    transition: transform 0.6s var(--easeInOutCirc) 0.2s;
  }

  &.cover-visible {
    & #map-modern #map {
      transform: scale(1.4);
      transition: transform 0.6s var(--easeInOutCirc) 0s;
    }
    & .cover-widget {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s var(--easeInOutCirc) 0.2s, visibility 0s;
    }
    /* & .scrolldown-indicator { */
    /*   color: white; */
    /*   text-shadow: 0 0 10px rgba(0, 0, 0, 0.25); */
    /*   transition: color 0.3s var(--easeInOutCirc) 0.2s, text-shadow 0.3s var(--easeInOutCirc) 0.2s; */
    /* } */
  }
}


/*-------------------------------------------
// Modern map
//-----------------------------------------*/
#map-modern {
  display: none;

  @media (--lg) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 1;
    background: url(/app/themes/taxi-stockholm/static/img/map-large.gif);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

/*-------------------------------------------
// Package Module
//-----------------------------------------*/

.package-booking-module-modern {
  display: flex;
  position: relative;
  background: #999;
  overflow: visible;
  flex-direction: column;

  @media (--lg) {
    min-height: 609px;
    height: calc(100vh - 100px);
    padding: 40px 40px 70px;
    flex-direction: column-reverse;
  }
}

/*-------------------------------------------
// Active orders
//-----------------------------------------*/
#active-bookings {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  transform: translateX(100%);
  z-index: 50;

  &.fixed {
    position: fixed;
  }
}

/*-------------------------------------------
// Login status
//-----------------------------------------*/
@media (--sm) {
  .login-status-modern {
    /*position: absolute;
    height: 32px;
    top: 20px;
    pointer-events: all;*/
  }
}

/*-------------------------------------------
// Status messages
//-----------------------------------------*/
#status-messages {
	flex: 1 0 100%;
	width: 100%;
	left: 0;
	z-index: 30;
}
