:root {
  --color-yellow: #FEBF00;
  --color-dark-yellow: #E3AA00;
  --color-cool-gray: #EFF0F2;
  --color-black-10: rgba(0, 0, 0, 0.1);
  --color-black-20: rgba(0, 0, 0, 0.2);
  --color-black-30: rgba(0, 0, 0, 0.3);
  --color-black-50: rgba(0, 0, 0, 0.5);
  --color-border-gray: #E6E6E8;

  --border-radius: 5px;
  --border-radius-large: 12px;
  --spacing: 8px;
  --padding: calc(var(--spacing) * 3);
  --padding-small: var(--spacing);
  --box-shadow: 0px 0px 40px grey;
  --shadow-filter: drop-shadow(0px 16px 24px rgba(50, 50, 71, 0.08)) drop-shadow(0px 32px 32px rgba(50, 50, 71, 0.08));
}

.modern-component {
  & *, & *:before, & *:after{
    box-sizing: border-box;
    font-family: "Neue Helvetica W01", "Helvetica Neue", sans-serif;
  }
}

/* input:-webkit-autofill, */
/* input:-webkit-autofill:hover, */
/* input:-webkit-autofill:focus, */
/* textarea:-webkit-autofill, */
/* textarea:-webkit-autofill:hover, */
/* textarea:-webkit-autofill:focus, */
/* select:-webkit-autofill, */
/* select:-webkit-autofill:hover, */
/* select:-webkit-autofill:focus { */
/*   -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important; */
/* } */
