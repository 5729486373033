:root {
  --color-yellow: #febf00;
  --color-dark-yellow: #e3aa00;
  --color-cool-gray: #eff0f2;
  --color-black-10: #0000001a;
  --color-black-20: #0003;
  --color-black-30: #0000004d;
  --color-black-50: #00000080;
  --color-border-gray: #e6e6e8;
  --border-radius: 5px;
  --border-radius-large: 12px;
  --spacing: 8px;
  --padding: calc(var(--spacing) * 3);
  --padding-small: var(--spacing);
  --box-shadow: 0px 0px 40px grey;
  --shadow-filter: drop-shadow(0px 16px 24px #32324714) drop-shadow(0px 32px 32px #32324714);
}

.modern-component *, .modern-component :before, .modern-component :after {
  box-sizing: border-box;
  font-family: Neue Helvetica W01, Helvetica Neue, sans-serif;
}

:root {
  --easeInCubic: cubic-bezier(.55, .055, .675, .19);
  --easeOutCubic: cubic-bezier(.215, .61, .355, 1);
  --easeInOutCubic: cubic-bezier(.645, .045, .355, 1);
  --easeInCirc: cubic-bezier(.6, .04, .98, .335);
  --easeOutCirc: cubic-bezier(.075, .82, .165, 1);
  --easeInOutCirc: cubic-bezier(.785, .135, .15, .86);
  --easeInExpo: cubic-bezier(.95, .05, .795, .035);
  --easeOutExpo: cubic-bezier(.19, 1, .22, 1);
  --easeInOutExpo: cubic-bezier(1, 0, 0, 1);
  --easeInQuad: cubic-bezier(.55, .085, .68, .53);
  --easeOutQuad: cubic-bezier(.25, .46, .45, .94);
  --easeInOutQuad: cubic-bezier(.455, .03, .515, .955);
  --easeInQuart: cubic-bezier(.895, .03, .685, .22);
  --easeOutQuart: cubic-bezier(.165, .84, .44, 1);
  --easeInOutQuart: cubic-bezier(.77, 0, .175, 1);
  --easeInQuint: cubic-bezier(.755, .05, .855, .06);
  --easeOutQuint: cubic-bezier(.23, 1, .32, 1);
  --easeInOutQuint: cubic-bezier(.86, 0, .07, 1);
  --easeInSine: cubic-bezier(.47, 0, .745, .715);
  --easeOutSine: cubic-bezier(.39, .575, .565, 1);
  --easeInOutSine: cubic-bezier(.445, .05, .55, .95);
  --easeInBack: cubic-bezier(.6, -.28, .735, .045);
  --easeOutBack: cubic-bezier(.175, .885, .32, 1.275);
  --easeInOutBack: cubic-bezier(.68, -.55, .265, 1.55);
}

.booking-module-modern {
  background: #999;
  flex-direction: column;
  display: flex;
  position: relative;
  overflow: visible;
}

.booking-module-modern .booking-module-modern-form {
  width: 100%;
  pointer-events: none;
  filter: drop-shadow(var(--box-shadow));
  z-index: 40;
}

@media (min-width: 970px) {
  .booking-module-modern {
    min-height: 609px;
    height: calc(100vh - 100px);
    flex-direction: column-reverse;
    padding: 40px;
  }
}

.booking-module-modern .cover-widget {
  background-color: #000;
  background-position: center;
  background-size: cover;
}

.booking-module-modern .cover-widget video {
  z-index: 1;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
}

@media (min-width: 1400px) {
  .booking-module-modern .cover-widget {
    width: 100%;
  }
}

@media (min-width: 970px) {
  .booking-module-modern .cover-widget {
    width: 100%;
    height: 100%;
    z-index: 20;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s var(--easeInOutCirc), visibility 0s .3s;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.booking-module-modern .cover-widget .cover-container {
  padding: 30px 20px 30px 40px;
}

@media (min-width: 970px) {
  .booking-module-modern .cover-widget .cover-container {
    align-items: initial;
    justify-content: initial;
    padding: 80px 60px;
  }
}

@media (min-width: 1600px) {
  .booking-module-modern .cover-widget .cover-container {
    padding: 120px 170px;
  }
}

@media (min-width: 1920px) {
  .booking-module-modern .cover-widget .cover-container {
    padding: 120px 195px;
  }
}

.booking-module-modern .cover-widget .cover-content {
  max-width: none;
  z-index: 2;
  position: relative;
}

.booking-module-modern .cover-widget .cover-content p {
  width: 100%;
  font-size: 16px;
  line-height: 22px;
}

@media (min-width: 768px) {
  .booking-module-modern .cover-widget .cover-content p {
    font-size: 18px;
    line-height: 24px;
  }
}

@media (min-width: 970px) {
  .booking-module-modern .cover-widget .cover-content p {
    width: calc(100% - 639px);
  }
}

@media (min-width: 1100px) {
  .booking-module-modern .cover-widget .cover-content p {
    font-size: 20px;
    line-height: 26px;
  }
}

@media (min-width: 1600px) {
  .booking-module-modern .cover-widget .cover-content p {
    width: calc(100% - 684px);
  }
}

@media (min-width: 970px) {
  .booking-module-modern .cover-widget .cover-content h2 {
    max-width: 960px;
    font-size: 60px;
    line-height: 70px;
  }
}

@media (min-width: 1600px) {
  .booking-module-modern .cover-widget .cover-content h2 {
    font-size: 72px;
    line-height: 80px;
  }
}

.booking-module-modern .cover-widget.right {
  background-position: 100% 0;
}

.booking-module-modern .cover-widget.left {
  background-position: 0 0;
}

.booking-module-modern #map-modern {
  display: none;
}

@media (min-width: 970px) {
  .booking-module-modern #map-modern {
    display: block;
  }
}

.booking-module-modern #map-modern #map {
  transition: transform .6s var(--easeInOutCirc) .2s;
  transform: scale(1);
}

.booking-module-modern.cover-visible #map-modern #map {
  transition: transform .6s var(--easeInOutCirc) 0s;
  transform: scale(1.4);
}

.booking-module-modern.cover-visible .cover-widget {
  opacity: 1;
  visibility: visible;
  transition: opacity .3s var(--easeInOutCirc) .2s, visibility 0s;
}

#map-modern {
  display: none;
}

@media (min-width: 970px) {
  #map-modern {
    width: 100%;
    height: 100%;
    opacity: 1;
    background: url("/app/themes/taxi-stockholm/static/img/map-large.gif") center / cover no-repeat;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}

.package-booking-module-modern {
  background: #999;
  flex-direction: column;
  display: flex;
  position: relative;
  overflow: visible;
}

@media (min-width: 970px) {
  .package-booking-module-modern {
    min-height: 609px;
    height: calc(100vh - 100px);
    flex-direction: column-reverse;
    padding: 40px 40px 70px;
  }
}

#active-bookings {
  height: 100%;
  z-index: 50;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
}

#active-bookings.fixed {
  position: fixed;
}

#status-messages {
  width: 100%;
  z-index: 30;
  flex: 1 0 100%;
  left: 0;
}

.wTEfHW_wrapper {
  width: 100%;
  padding: 0 calc(var(--spacing) * 3) var(--spacing);
  gap: var(--spacing);
  flex-direction: column;
  display: flex;
}

.wTEfHW_title {
  margin-bottom: calc(var(--spacing) * 2);
  font-size: 16px;
  font-weight: 500;
}

.wTEfHW_message {
  font-size: 13px;
}

.L5uIoW_wrapper {
  width: 100%;
  padding: 0 calc(var(--spacing) * 3) var(--spacing);
  gap: var(--spacing);
  flex-direction: column;
  display: flex;
}

.L5uIoW_title {
  margin-bottom: calc(var(--spacing) * 2);
  font-size: 16px;
  font-weight: 500;
}

.L5uIoW_message {
  font-size: 13px;
}

.bXMCWq_wrapper {
  width: 100%;
  padding: 0 calc(var(--spacing) * 3) var(--spacing);
  gap: var(--spacing);
  flex-direction: column;
  display: flex;
}

.bXMCWq_title {
  margin-bottom: calc(var(--spacing) * 2);
  font-size: 16px;
  font-weight: 500;
}

.bXMCWq_message {
  font-size: 13px;
}

._3m1d2q_wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

._3m1d2q_label {
  width: 40px;
  height: 20px;
  background: var(--color-black-10);
  cursor: pointer;
  border-radius: 10px;
  flex-shrink: 0;
  transition: background-color .3s;
  position: relative;
}

._3m1d2q_label:after {
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid var(--color-border-gray);
  background: #fff;
  border-radius: 50%;
  transition: transform .2s;
  position: absolute;
  top: 0;
  left: 0;
}

._3m1d2q_label._3m1d2q_isChecked {
  background: var(--color-yellow);
  transition: background-color .3s;
}

._3m1d2q_label._3m1d2q_isChecked:after {
  transition: transform .2s;
  transform: translateX(100%);
}

._3m1d2q_label._3m1d2q_focus-visible {
  outline: none;
}

._3m1d2q_label:focus-visible {
  outline: none;
}

._3m1d2q_label._3m1d2q_focus-visible:before, ._3m1d2q_label:focus-visible:before {
  content: "";
  width: 44px;
  height: 24px;
  box-shadow: 0px 0px 0px 2px var(--color-yellow);
  border-radius: 14px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

._3m1d2q_field {
  opacity: 0;
  position: absolute;
}

._1BN4LW_wrapper {
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.0' width='64' height='64' viewBox='0 0 128 128'%3E%3Cg%3E%3Cpath d='M59.6 0h8v40h-8V0z'/%3E%3Cpath d='M92.19 6.374l6.928 4-20 34.641-6.929-4 20-34.64zM117.226 28.19l4 6.928-34.641 20-4-6.929 34.64-20zM128 59.6v8H88v-8h40zM121.626 92.19l-4 6.928-34.641-20 4-6.929 34.64 20z' fill='%23ccc' fill-opacity='.2'/%3E%3Cpath d='M99.81 117.226l-6.928 4-20-34.641 6.929-4 20 34.64z' fill='%23b2b2b2' fill-opacity='.3'/%3E%3Cpath d='M68.4 128h-8V88h8v40z' fill='%23999' fill-opacity='.4'/%3E%3Cpath d='M35.81 121.626l-6.928-4 20-34.641 6.929 4-20 34.64z' fill='%237f7f7f' fill-opacity='.5'/%3E%3Cpath d='M10.774 99.81l-4-6.928 34.641-20 4 6.929-34.64 20z' fill='%23666' fill-opacity='.6'/%3E%3Cpath d='M0 68.4v-8h40v8H0z' fill='%234c4c4c' fill-opacity='.7'/%3E%3Cpath d='M6.374 35.81l4-6.928 34.641 20-4 6.929-34.64-20z' fill='%23333' fill-opacity='.8'/%3E%3Cpath d='M28.19 10.774l6.928-4 20 34.641-6.929 4-20-34.64z' fill='%23191919' fill-opacity='.9'/%3E%3CanimateTransform attributeName='transform' type='rotate' values='0 64 64;30 64 64;60 64 64;90 64 64;120 64 64;150 64 64;180 64 64;210 64 64;240 64 64;270 64 64;300 64 64;330 64 64' calcMode='discrete' dur='600ms' repeatCount='indefinite'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  display: inline-block;
}

._1BN4LW_wrapper._1BN4LW_light {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg xmlns:svg='http://www.w3.org/2000/svg' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.0' width='64px' height='64px' viewBox='0 0 128 128' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='1'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.2' transform='rotate(30 64 64)'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.2' transform='rotate(60 64 64)'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.2' transform='rotate(90 64 64)'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.2' transform='rotate(120 64 64)'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.3' transform='rotate(150 64 64)'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.4' transform='rotate(180 64 64)'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.5' transform='rotate(210 64 64)'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.6' transform='rotate(240 64 64)'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.7' transform='rotate(270 64 64)'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.8' transform='rotate(300 64 64)'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.9' transform='rotate(330 64 64)'/%3E%3CanimateTransform attributeName='transform' type='rotate' values='0 64 64;30 64 64;60 64 64;90 64 64;120 64 64;150 64 64;180 64 64;210 64 64;240 64 64;270 64 64;300 64 64;330 64 64' calcMode='discrete' dur='600ms' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/g%3E%3C/svg%3E%0A");
}

.mpun5a_wrapper {
  opacity: 0;
  color: #fff;
  white-space: nowrap;
  transition: transform .3s var(--easeInOutCirc), opacity .3s var(--easeInOutCirc);
  pointer-events: none;
  z-index: 10;
  background: #000;
  border-radius: 5px;
  padding: 0 15px 0 13px;
  font-size: 12px;
  font-weight: 700;
  line-height: 30px;
  position: absolute;
  top: 13px;
  right: 13px;
  transform: translateX(26px);
}

.mpun5a_wrapper.mpun5a_isVisible {
  opacity: 1;
  transform: translateX(0);
}

.mpun5a_wrapper:before {
  content: "";
  height: 0;
  width: 0;
  border: 5px solid #0000;
  border-right-color: #000;
  margin-top: 10px;
  position: absolute;
  left: -10px;
}

.k5Wh1q_title {
  font-size: 14px;
  font-weight: 500;
}

.k5Wh1q_info {
  color: var(--color-black-50);
  margin-top: 2px;
  font-size: 11px;
  line-height: 1.2em;
}

.MXSnLG_wrapper {
  align-items: center;
  display: flex;
}

.MXSnLG_number, .MXSnLG_separator {
  height: 112px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.MXSnLG_number {
  width: 116px;
  border-radius: var(--border-radius);
  background: #fff;
  outline: none;
  position: relative;
}

.MXSnLG_number:focus:after {
  content: "";
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  background: var(--color-yellow);
  z-index: 1;
  border-radius: 5px;
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
}

.MXSnLG_text {
  z-index: 2;
  font-size: 56px;
  position: relative;
}

.MXSnLG_separator {
  flex: 1;
  font-size: 56px;
}

._0TPBTq_subhead {
  font-size: 24px;
}

@media (min-width: 970px) {
  ._0TPBTq_subhead {
    display: none;
  }
}

._0TPBTq_desktop {
  display: none;
}

@media (min-width: 970px) {
  ._0TPBTq_desktop {
    display: block;
  }
}

.fXA-ca_options {
  flex-wrap: wrap;
  gap: 2px;
  margin-top: 5px;
  display: flex;
}

.fXA-ca_option {
  color: #000;
  background: var(--color-yellow);
  border-radius: var(--border-radius);
  padding: 4px 6px;
  font-size: 11px;
  line-height: 12px;
}

.pG7ELG_wrapper {
  width: 64px;
  height: 24px;
  color: #000;
  background: #fff;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  display: flex;
}

.pG7ELG_button {
  color: var(--color-black-30);
  width: 24px;
  height: 100%;
}

.eRDSla_wrapper {
  background: var(--color-yellow);
  padding: 0 var(--padding);
}

@media (min-width: 970px) {
  .eRDSla_wrapper {
    border-top-left-radius: var(--border-radius-large);
    border-top-right-radius: var(--border-radius-large);
  }
}

.eRDSla_item {
  padding: calc(var(--spacing) * 2) 0 calc(var(--spacing) * 2) 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_652_870)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 14.4333C11.553 14.4333 14.4333 11.553 14.4333 8C14.4333 4.44697 11.553 1.56667 8 1.56667C4.44697 1.56667 1.56667 4.44697 1.56667 8C1.56667 11.553 4.44697 14.4333 8 14.4333ZM8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5Z' fill='black'/%3E%3Crect x='7.19995' y='4' width='1.6' height='1.6' rx='0.8' fill='black'/%3E%3Crect x='7.46655' y='6.66669' width='1.06667' height='5.33333' rx='0.213333' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_652_870'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-position: 0;
  background-repeat: no-repeat;
  display: flex;
}

.eRDSla_item + .eRDSla_item {
  border-top: 1px solid #0000001a;
}

.eRDSla_text, .eRDSla_button {
  color: #000;
  white-space: nowrap;
  font-size: 13px;
  line-height: 1em;
}

.eRDSla_text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.eRDSla_button {
  margin-left: var(--spacing);
  text-decoration: underline;
  display: none;
}

.eRDSla_truncated + .eRDSla_button {
  display: block;
}

.fVTPsW_wrapper {
  width: 100%;
  height: 100%;
  padding: var(--padding-small);
  z-index: 100;
  background: #00000080;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.fVTPsW_wrapper.fVTPsW_isOpen {
  display: flex;
}

.fVTPsW_wrapper.fVTPsW_lightBackground {
  background: #ffffff80;
}

.fVTPsW_wrapper.fVTPsW_absolute {
  position: absolute;
}

.fVTPsW_wrapper.fVTPsW_rounded {
  border-radius: var(--border-radius-large);
}

.fVTPsW_container {
  width: 100%;
  border-radius: var(--border-radius-large);
  background: #fff;
  padding: 30px 0 0;
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .fVTPsW_container {
    max-width: 420px;
  }
}

.fVTPsW_lightBackground .fVTPsW_container {
  box-shadow: 0 0 10px #00000040;
}

.Nwwt0a_svg {
  fill: #4c4c4c;
}

.eI6iTW_wrapper {
  margin-bottom: calc(var(--spacing) * 4);
  font-size: 28px;
  font-weight: 500;
  display: inline-block;
}

@media (min-width: 970px) {
  .eI6iTW_wrapper {
    font-size: 32px;
  }
}

.eI6iTW_wrapper.eI6iTW_isLoading:after {
  content: "";
  width: 24px;
  height: 24px;
  margin-left: calc(var(--spacing) * 2);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.0' width='64' height='64' viewBox='0 0 128 128'%3E%3Cg%3E%3Cpath d='M59.6 0h8v40h-8V0z'/%3E%3Cpath d='M92.19 6.374l6.928 4-20 34.641-6.929-4 20-34.64zM117.226 28.19l4 6.928-34.641 20-4-6.929 34.64-20zM128 59.6v8H88v-8h40zM121.626 92.19l-4 6.928-34.641-20 4-6.929 34.64 20z' fill='%23ccc' fill-opacity='.2'/%3E%3Cpath d='M99.81 117.226l-6.928 4-20-34.641 6.929-4 20 34.64z' fill='%23b2b2b2' fill-opacity='.3'/%3E%3Cpath d='M68.4 128h-8V88h8v40z' fill='%23999' fill-opacity='.4'/%3E%3Cpath d='M35.81 121.626l-6.928-4 20-34.641 6.929 4-20 34.64z' fill='%237f7f7f' fill-opacity='.5'/%3E%3Cpath d='M10.774 99.81l-4-6.928 34.641-20 4 6.929-34.64 20z' fill='%23666' fill-opacity='.6'/%3E%3Cpath d='M0 68.4v-8h40v8H0z' fill='%234c4c4c' fill-opacity='.7'/%3E%3Cpath d='M6.374 35.81l4-6.928 34.641 20-4 6.929-34.64-20z' fill='%23333' fill-opacity='.8'/%3E%3Cpath d='M28.19 10.774l6.928-4 20 34.641-6.929 4-20-34.64z' fill='%23191919' fill-opacity='.9'/%3E%3CanimateTransform attributeName='transform' type='rotate' values='0 64 64;30 64 64;60 64 64;90 64 64;120 64 64;150 64 64;180 64 64;210 64 64;240 64 64;270 64 64;300 64 64;330 64 64' calcMode='discrete' dur='600ms' repeatCount='indefinite'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  display: inline-block;
}

.PrQI1W_wrapper {
  background: var(--color-cool-gray);
  padding: var(--padding);
}

.PrQI1W_wrapper.PrQI1W_isLoading {
  pointer-events: none;
}

@media (min-width: 970px) {
  .PrQI1W_wrapper {
    border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
  }
}

.VpuVxG_wrapper {
  height: 56px;
  padding: 0 calc(var(--spacing) * 2);
  border-radius: var(--border-radius);
  background: #fff;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  display: flex;
}

.VpuVxG_wrapper.VpuVxG_clickable {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.VpuVxG_wrapper.VpuVxG_clickable > * {
  pointer-events: none;
}

.VpuVxG_wrapper.VpuVxG_alternateBackground {
  background: var(--color-cool-gray);
}

.VpuVxG_wrapper.VpuVxG_reverseLayout {
  flex-direction: row-reverse;
  justify-content: left;
}

.VpuVxG_wrapper.VpuVxG_isDisabled {
  cursor: default;
  pointer-events: none;
}

.VpuVxG_wrapper.VpuVxG_isDisabled > * {
  opacity: .2;
}

.VpuVxG_wrapper.VpuVxG_larger {
  height: 73px;
}

.VpuVxG_wrapper.VpuVxG_responsive {
  height: auto;
  padding: calc(var(--spacing) * 2);
}

.kg1-YG_wrapper {
  position: relative;
}

.kg1-YG_wrapper.kg1-YG_isOpen .kg1-YG_button {
  transform: scaleY(-100%);
}

.kg1-YG_wrapper.kg1-YG_isOpen .kg1-YG_dropDown {
  display: block;
}

.kg1-YG_button {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.kg1-YG_button.kg1-YG_focus-visible {
  outline: none;
}

.kg1-YG_button:focus-visible {
  outline: none;
}

.kg1-YG_button.kg1-YG_focus-visible:before, .kg1-YG_button:focus-visible:before {
  content: "";
  width: 24px;
  height: 24px;
  box-shadow: 0px 0px 0px 2px var(--color-yellow);
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.kg1-YG_dropDown {
  width: 100%;
  background: var(--color-cool-gray);
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  z-index: 1;
  margin-top: -5px;
  padding-top: 5px;
  display: none;
  position: absolute;
}

.r0lE_q_content {
  align-items: center;
  gap: 10px;
  display: flex;
}

.r0lE_q_button {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  position: relative;
}

.r0lE_q_button.r0lE_q_focus-visible:before, .r0lE_q_button:focus-visible:before {
  content: "";
  width: 24px;
  height: 24px;
  box-shadow: 0px 0px 0px 2px var(--color-yellow);
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.r0lE_q_button:hover {
  background: var(--color-yellow);
}

.r0lE_q_button:hover > svg {
  fill-opacity: 1;
}

.Oc3t5G_wrapper {
  top: calc(var(--spacing) * 2);
  right: calc(var(--spacing) * 2);
  pointer-events: auto;
  position: absolute;
}

.Oc3t5G_button {
  width: 32px;
}

@media (min-width: 768px) {
  .Oc3t5G_button.Oc3t5G_isLarge {
    width: 48px;
  }
}

@media (pointer: fine) {
  .Oc3t5G_button:hover .Oc3t5G_fg {
    opacity: 1;
  }

  .Oc3t5G_button:hover .Oc3t5G_bg {
    fill: var(--color-yellow);
  }
}

.Oc3t5G_svg {
  width: 100%;
  height: 32px;
}

.Oc3t5G_svg.Oc3t5G_isLarge {
  height: 48px;
}

.Oc3t5G_fg {
  fill: #000;
  opacity: .5;
  transition: opacity .2s var(--easeOutQuad);
}

.Oc3t5G_bg {
  fill: #0000000d;
  transition: fill .2s var(--easeOutQuad);
}

.vbi8MG_icon {
  width: 25px;
  height: 25px;
  background: var(--color-yellow);
  color: #000;
  -webkit-user-select: none;
  user-select: none;
  z-index: 3;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-family: Roboto, Arial, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 25px;
  display: flex;
  position: absolute;
}

.vbi8MG_icon.vbi8MG_small {
  width: 20px;
  height: 20px;
  font-size: 10px;
}

.vbi8MG_icon.vbi8MG_gray {
  background: #a7a8a9;
}

._2rSoGW_button {
  width: 25px;
  height: 25px;
  padding-left: 7px;
  position: absolute;
  top: -9px;
}

.CZ7SeW_wrapper {
  padding: var(--padding-small);
  background: #fff;
}

.CZ7SeW_wrapper.CZ7SeW_isLoading {
  pointer-events: none;
}

@media (min-width: 970px) {
  .CZ7SeW_wrapper {
    border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
  }
}

.CZ7SeW_buttons {
  gap: var(--spacing);
  align-items: start;
  display: flex;
}

.CZ7SeW_button {
  width: 100%;
  height: 56px;
  color: #000;
  background-color: var(--color-black-10);
  border-radius: var(--border-radius);
  text-transform: uppercase;
  letter-spacing: .11em;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.1em;
  transition: background-color .4s;
}

.CZ7SeW_button.CZ7SeW_focus-visible {
  background-color: var(--color-black-20);
}

.CZ7SeW_button:focus-visible {
  background-color: var(--color-black-20);
}

@media (pointer: fine) {
  .CZ7SeW_button:hover {
    background-color: var(--color-black-20);
  }
}

.CZ7SeW_button.CZ7SeW_isPrimary {
  background-color: var(--color-yellow);
}

.CZ7SeW_button.CZ7SeW_isPrimary.CZ7SeW_focus-visible {
  background-color: var(--color-dark-yellow);
}

.CZ7SeW_button.CZ7SeW_isPrimary:focus-visible {
  background-color: var(--color-dark-yellow);
}

@media (pointer: fine) {
  .CZ7SeW_button.CZ7SeW_isPrimary:hover:not(.CZ7SeW_isLoading) {
    background-color: var(--color-dark-yellow);
  }
}

.CZ7SeW_button.CZ7SeW_isPrimary:disabled {
  color: var(--color-black-20);
}

.CZ7SeW_button.CZ7SeW_isBack {
  width: 56px;
  height: 56px;
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='13' viewBox='0 0 8 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 1.5L1.5 6.5L6.5 11.5' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
}

.CZ7SeW_button.CZ7SeW_isLoading {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.0' width='64' height='64' viewBox='0 0 128 128'%3E%3Cg%3E%3Cpath d='M59.6 0h8v40h-8V0z'/%3E%3Cpath d='M92.19 6.374l6.928 4-20 34.641-6.929-4 20-34.64zM117.226 28.19l4 6.928-34.641 20-4-6.929 34.64-20zM128 59.6v8H88v-8h40zM121.626 92.19l-4 6.928-34.641-20 4-6.929 34.64 20z' fill='%23ccc' fill-opacity='.2'/%3E%3Cpath d='M99.81 117.226l-6.928 4-20-34.641 6.929-4 20 34.64z' fill='%23b2b2b2' fill-opacity='.3'/%3E%3Cpath d='M68.4 128h-8V88h8v40z' fill='%23999' fill-opacity='.4'/%3E%3Cpath d='M35.81 121.626l-6.928-4 20-34.641 6.929 4-20 34.64z' fill='%237f7f7f' fill-opacity='.5'/%3E%3Cpath d='M10.774 99.81l-4-6.928 34.641-20 4 6.929-34.64 20z' fill='%23666' fill-opacity='.6'/%3E%3Cpath d='M0 68.4v-8h40v8H0z' fill='%234c4c4c' fill-opacity='.7'/%3E%3Cpath d='M6.374 35.81l4-6.928 34.641 20-4 6.929-34.64-20z' fill='%23333' fill-opacity='.8'/%3E%3Cpath d='M28.19 10.774l6.928-4 20 34.641-6.929 4-20-34.64z' fill='%23191919' fill-opacity='.9'/%3E%3CanimateTransform attributeName='transform' type='rotate' values='0 64 64;30 64 64;60 64 64;90 64 64;120 64 64;150 64 64;180 64 64;210 64 64;240 64 64;270 64 64;300 64 64;330 64 64' calcMode='discrete' dur='600ms' repeatCount='indefinite'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  color: #0000 !important;
}

.CZ7SeW_button:disabled {
  cursor: default;
  pointer-events: none;
}

.CZ7SeW_button:disabled:not(.CZ7SeW_isPrimary) {
  background-color: var(--color-cool-gray);
  color: var(--color-black-10);
}

.N0Qq-W_options {
  flex-wrap: wrap;
  gap: 2px;
  margin-top: 5px;
  display: flex;
}

.N0Qq-W_option {
  color: #000;
  background: var(--color-yellow);
  border-radius: var(--border-radius);
  padding: 4px 6px;
  font-size: 11px;
  line-height: 12px;
}

.N0Qq-W_showStops {
  position: relative;
}

.N0Qq-W_showStops:before {
  content: "";
  width: 1px;
  height: calc(100% - 45px);
  border-left: 1px dotted #a7a8a9;
  display: block;
  position: absolute;
  top: 18px;
  left: 10px;
}

.N0Qq-W_showStops .N0Qq-W_departure, .N0Qq-W_showStops .N0Qq-W_destination {
  position: relative;
}

.N0Qq-W_alignStart {
  align-items: start;
}

.N0Qq-W_hasStops {
  padding-left: 30px;
}

.N0Qq-W_stops {
  flex-direction: column;
  gap: 10px;
  padding-left: 30px;
  display: flex;
}

.N0Qq-W_stop {
  font-size: 15px;
  position: relative;
}

.N0Qq-W_stop:before {
  content: "";
  width: 7px;
  height: 7px;
  background: #a7a8a9;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 4px;
  left: -23px;
}

.N0Qq-W_charIcon {
  left: 0;
}

.Uu9omG_wrapper {
  width: 100%;
  padding: 0 calc(var(--spacing) * 3) var(--spacing);
  gap: var(--spacing);
  flex-direction: column;
  display: flex;
}

.Uu9omG_title {
  text-align: center;
  margin-bottom: calc(var(--spacing) * 2);
  font-size: 16px;
  font-weight: 500;
}

.Uu9omG_message {
  text-align: center;
  font-size: 13px;
}

._0S-3CW_time {
  display: none;
}

@media (min-width: 970px) {
  ._0S-3CW_time {
    display: block;
  }
}

.ki7NEa_label {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 12px;
  transition: background-color .2s;
  position: relative;
}

.ki7NEa_label.ki7NEa_focus-visible {
  outline: none;
}

.ki7NEa_label:focus-visible {
  outline: none;
}

.ki7NEa_label.ki7NEa_focus-visible:before, .ki7NEa_label:focus-visible:before {
  content: "";
  width: 24px;
  height: 24px;
  box-shadow: 0px 0px 0px 2px var(--color-yellow);
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ki7NEa_label:after {
  content: "";
  transition: transform .4s;
  transform: scale(0);
}

.ki7NEa_label.ki7NEa_isChecked {
  background-color: var(--color-yellow);
  transition: background-color .2s;
}

.ki7NEa_label.ki7NEa_isChecked:after {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.2L4.29412 6L9 1' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  transition: transform .4s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1);
}

.ki7NEa_eta {
  color: #fff;
  background: #000;
  border-radius: 5px;
  margin-left: 5px;
  padding: 5px;
  font-size: 11px;
}

.ki7NEa_right {
  align-items: center;
  gap: 10px;
  display: flex;
}

.ki7NEa_field {
  visibility: hidden;
}

.s-oIOW_wrapper {
  width: 100%;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.s-oIOW_buttonWrapper {
  position: relative;
}

.s-oIOW_button {
  align-items: center;
  gap: var(--spacing);
  height: 56px;
  padding: 0 calc(var(--spacing) * 2);
  border-radius: var(--border-radius);
  cursor: pointer;
  background: #fff;
  border: none;
  font-size: 16px;
  display: inline-flex;
}

.s-oIOW_button.s-oIOW_isOpen .s-oIOW_icon {
  transform: scaleY(-1);
}

@media (max-width: 969px) {
  .s-oIOW_button {
    pointer-events: none;
  }
}

.s-oIOW_icon {
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
}

.s-oIOW_select {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.s-oIOW_dropdown {
  width: 100%;
  max-height: 280px;
  margin-top: var(--spacing);
  border-radius: var(--border-radius);
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  background: #fff;
  flex-direction: column;
  padding: 0;
  display: flex;
  position: absolute;
  top: 100%;
  overflow: hidden;
  box-shadow: 0 10px 20px #00000026;
}

.s-oIOW_dropdown.s-oIOW_renderAbove {
  top: calc(0px - var(--spacing));
  margin-top: 0;
  transform: translateY(-100%);
  box-shadow: 0 -10px 20px #00000026;
}

.s-oIOW_list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-x: hidden;
  overflow-y: scroll;
}

.s-oIOW_item {
  width: 100%;
}

.s-oIOW_text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.s-oIOW_itemButton {
  height: 40px;
  align-items: center;
  gap: var(--spacing);
  width: 100%;
  padding: 0 calc(var(--spacing) * 2);
  white-space: nowrap;
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  font-size: 14px;
  display: flex;
}

@media (pointer: fine) {
  .s-oIOW_itemButton:hover {
    background: var(--color-yellow);
  }
}

.s-oIOW_flag {
  font-size: 30px;
}

.s-oIOW_flag.s-oIOW_selected {
  line-height: 56px;
}

.s-oIOW_inputWrapper {
  align-items: center;
  gap: calc(var(--spacing) / 2);
  width: 100%;
  height: 56px;
  padding: 0 calc(var(--spacing) * 2);
  border-radius: var(--border-radius);
  background: #fff;
  flex: 1;
  display: flex;
}

.s-oIOW_dialCode {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.s-oIOW_dialCode, .s-oIOW_input {
  height: 100%;
  font-family: Arial;
  font-size: 16px;
  line-height: 56px;
}

.s-oIOW_input {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  border: none;
  flex: 1;
  padding: 0;
}

.s-oIOW_input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 1000px #fff !important;
}

.s-oIOW_input:autofill, .s-oIOW_input:-webkit-autofill, .s-oIOW_input:-webkit-autofill:hover, .s-oIOW_input:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 0 0 1000px #fff !important;
}

.s-oIOW_input:focus {
  outline: none;
}

.spcmba_flag {
  flex-shrink: 0;
  font-size: 30px;
  line-height: 56px;
}

.spcmba_fallback {
  width: 30px;
  flex-shrink: 0;
}

.v9qp_q_wrapper {
  align-items: center;
  gap: 10px;
  display: flex;
}

.v9qp_q_iconWrapper {
  width: 40px;
  height: 40px;
  position: relative;
}

.v9qp_q_icon {
  position: absolute;
}

.v9qp_q_icon.v9qp_q_ENVELOPE {
  width: 58px;
  height: 58px;
  top: -15px;
  left: -9px;
}

.v9qp_q_icon.v9qp_q_BOX {
  width: 74px;
  height: 74px;
  top: -22px;
  left: -17px;
}

.v9qp_q_icon.v9qp_q_BAG {
  width: 78px;
  height: 78px;
  top: -23px;
  left: -19px;
}

.v9qp_q_icon.v9qp_q_MOVING_BOX {
  width: 56px;
  height: 56px;
  top: -16px;
  left: -8px;
}

.v9qp_q_right {
  align-items: center;
  gap: 10px;
  display: flex;
}

.v9qp_q_field {
  width: 50px;
}

._483SWG_container {
  width: 100%;
}

._483SWG_fieldwrapper {
  font-size: 16px;
  font-weight: 500;
  position: relative;
}

._483SWG_fieldwrapper ._483SWG_icon {
  pointer-events: none;
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

._483SWG_fieldwrapper input {
  width: 100%;
  height: 56px;
  font-size: inherit;
  border-radius: var(--border-radius);
  z-index: 2;
  border: none;
  padding: 0 0 0 51px;
  font-weight: 500;
  position: relative;
}

._483SWG_fieldwrapper input::placeholder {
  color: var(--color-black-30);
}

._483SWG_fieldwrapper._483SWG_dropDownVisible input {
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}

@media (min-width: 970px) {
  ._483SWG_fieldwrapper._483SWG_dropDownVisible input {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }
}

._483SWG_fieldwrapper._483SWG_dropDownVisible ._483SWG_dropdown {
  display: block;
}

._483SWG_dropdown {
  width: 100%;
  max-height: 240px;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  z-index: 1;
  background: #fff;
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  position: absolute;
  overflow-y: scroll;
  box-shadow: 0 20px 20px #00000040;
}

@media (min-width: 970px) {
  ._483SWG_dropdown {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    top: 0;
    transform: translateY(-100%);
  }
}

._483SWG_item {
  font-size: inherit;
  cursor: pointer;
  background: #fff;
  padding: .5rem 20px;
}

._483SWG_item._483SWG_selected {
  background: var(--color-yellow);
}

@media (pointer: fine) {
  ._483SWG_item:hover {
    background: var(--color-yellow);
  }
}

._483SWG_locationbutton {
  width: 40px;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.7344 10C15.7344 13.4432 12.9432 16.2344 9.5 16.2344M15.7344 10C15.7344 6.55685 12.9432 3.76562 9.5 3.76562M15.7344 10H19M9.5 16.2344C6.05685 16.2344 3.26562 13.4432 3.26562 10M9.5 16.2344V19.5M3.26562 10C3.26562 6.55685 6.05685 3.76562 9.5 3.76562M3.26562 10H0M9.5 3.76562V0.5' stroke='black' stroke-opacity='0.3' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
}

._483SWG_charIcon {
  cursor: pointer;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

.jlqDWG_icon {
  width: 25px;
  height: 18px;
  stroke: var(--color-black-30);
}

.jlqDWG_icon.jlqDWG_active {
  stroke: var(--color-yellow);
}

._0zESMa_wrapper {
  position: relative;
}

._0zESMa_field {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 56px;
  padding: 0 calc(var(--spacing) * 2);
  border-radius: var(--border-radius);
  border: none;
  font-size: 16px;
}

._0zESMa_field:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 1000px #fff !important;
}

._0zESMa_field:autofill, ._0zESMa_field:-webkit-autofill, ._0zESMa_field:-webkit-autofill:hover, ._0zESMa_field:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 0 0 1000px #fff !important;
}

._0zESMa_field._0zESMa_border {
  border: 1px solid var(--color-cool-gray);
}

._0zESMa_field._0zESMa_alternateBackground {
  background: var(--color-cool-gray);
}

._0zESMa_field._0zESMa_alternateBackground:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px var(--color-cool-gray) inset !important;
}

._0zESMa_field._0zESMa_alternateBackground:autofill, ._0zESMa_field._0zESMa_alternateBackground:-webkit-autofill, ._0zESMa_field._0zESMa_alternateBackground:-webkit-autofill:hover, ._0zESMa_field._0zESMa_alternateBackground:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px var(--color-cool-gray) inset !important;
}

._0zESMa_field._0zESMa_uppercase {
  text-transform: uppercase;
}

._0zESMa_field._0zESMa_uppercase::placeholder {
  text-transform: none;
}

._0zESMa_changeIcon {
  top: 50%;
  right: calc(var(--spacing) * 2);
  pointer-events: none;
  z-index: 1;
  position: absolute;
}

.oupmzq_error {
  align-items: center;
  margin-top: 4px;
  display: flex;
}

.oupmzq_error.oupmzq_noMarginTop {
  margin-top: 0;
}

.oupmzq_icon {
  width: 15px;
}

.oupmzq_text {
  color: #da0a2b;
  margin-left: 4px;
  font-size: 11px;
}

.oK1Xaa_date {
  height: 56px;
  padding: 0 calc(var(--spacing) * 2);
  border-radius: var(--border-radius);
  background-color: #fff;
  font-size: 16px;
  line-height: 56px;
  display: block;
  position: relative;
}

.oK1Xaa_date:first-letter {
  text-transform: capitalize;
}

.oK1Xaa_date:after {
  content: "";
  width: 56px;
  height: 56px;
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='3' y='5' width='18' height='3' fill='black'/%3E%3Crect x='6' y='3' width='2' height='3' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3 9H21V21H3V9ZM6 12H8V14H6V12ZM8 16H6V18H8V16ZM11 16H13V18H11V16ZM18 16H16V18H18V16ZM11 12H13V14H11V12ZM18 12H16V14H18V12Z' fill='black'/%3E%3Crect x='16' y='3' width='2' height='3' fill='black'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.oK1Xaa_date .oK1Xaa_input {
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 970px) {
  .oK1Xaa_date {
    display: none;
  }
}

@media (max-width: 969px) {
  .react-datepicker {
    display: none !important;
  }
}

.react-datepicker {
  border-radius: none !important;
  border: none !important;
}

.react-datepicker__header {
  background-color: #fff !important;
  border: none !important;
}

.react-datepicker__current-month {
  text-transform: capitalize;
}

.react-datepicker__day--selected {
  color: #000 !important;
  background-color: var(--color-yellow) !important;
  border-radius: 50% !important;
}

.react-datepicker__day--keyboard-selected {
  color: #000 !important;
  background-color: var(--color-cool-gray) !important;
  border-radius: 50% !important;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon:before {
  content: "";
  height: 9px;
  width: 9px;
  border: 3px solid #ccc;
  border-width: 3px 3px 0 0;
  display: block;
  position: absolute;
  top: 6px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  width: 0;
  margin-left: -4px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  box-sizing: content-box;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border: 8px solid #0000;
  position: absolute;
  left: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-top: -8px;
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #aeaeae;
  top: -1px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  margin-bottom: -8px;
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after {
  border-top-color: #fff;
  border-bottom: none;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after {
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before {
  border-top-color: #aeaeae;
  bottom: -1px;
}

.react-datepicker-wrapper {
  width: 100%;
  border: 0;
  padding: 0;
  display: inline-block;
}

.react-datepicker {
  color: #000;
  background-color: #fff;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  font-family: Helvetica Neue, helvetica, arial, sans-serif;
  font-size: .8rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time, .react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
  padding-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  padding-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  padding-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: .3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: .3rem;
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll {
  margin: 0 2px;
  display: inline-block;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #000;
  margin-top: 0;
  font-size: .944rem;
  font-weight: bold;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  text-align: center;
  cursor: pointer;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  position: absolute;
  top: 2px;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 2px;
}

.react-datepicker__navigation--next {
  right: 2px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}

.react-datepicker__navigation--years {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
}

.react-datepicker__navigation:hover :before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  width: 0;
  font-size: 20px;
  position: relative;
  top: -1px;
}

.react-datepicker__navigation-icon--next {
  left: -2px;
}

.react-datepicker__navigation-icon--next:before {
  left: -7px;
  transform: rotate(45deg);
}

.react-datepicker__navigation-icon--previous {
  right: -2px;
}

.react-datepicker__navigation-icon--previous:before {
  right: -7px;
  transform: rotate(225deg);
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__year-wrapper {
  max-width: 180px;
  flex-wrap: wrap;
  display: flex;
}

.react-datepicker__year .react-datepicker__year-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__month {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  text-align: left;
  margin: 5px 0 10px 15px;
}

.react-datepicker__input-time-container .react-datepicker-time__caption, .react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  margin-left: 10px;
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button, .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  width: 85px;
  border-left: 1px solid #aeaeae;
}

.react-datepicker__time-container--with-today-button {
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  display: inline;
  position: absolute;
  top: 0;
  right: -72px;
}

.react-datepicker__time-container .react-datepicker__time {
  background: #fff;
  border-bottom-right-radius: .3rem;
  position: relative;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  text-align: center;
  border-bottom-right-radius: .3rem;
  margin: 0 auto;
  overflow-x: hidden;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  height: calc(195px + .85rem);
  width: 100%;
  box-sizing: content-box;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  overflow-y: scroll;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  white-space: nowrap;
  padding: 5px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  color: #fff;
  background-color: #216ba5;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: #0000;
}

.react-datepicker__week-number {
  color: #ccc;
  width: 1.7rem;
  text-align: center;
  margin: .166rem;
  line-height: 1.7rem;
  display: inline-block;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  background-color: #f0f0f0;
  border-radius: .3rem;
}

.react-datepicker__day-names, .react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #000;
  width: 1.7rem;
  text-align: center;
  margin: .166rem;
  line-height: 1.7rem;
  display: inline-block;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range, .react-datepicker__quarter--selected, .react-datepicker__quarter--in-selecting-range, .react-datepicker__quarter--in-range {
  color: #fff;
  background-color: #216ba5;
  border-radius: .3rem;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover, .react-datepicker__quarter--selected:hover, .react-datepicker__quarter--in-selecting-range:hover, .react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled, .react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover, .react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: #0000;
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
  cursor: pointer;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  background-color: #f0f0f0;
  border-radius: .3rem;
}

.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted, .react-datepicker__year-text--highlighted {
  color: #fff;
  background-color: #3dcc4a;
  border-radius: .3rem;
}

.react-datepicker__day--highlighted:hover, .react-datepicker__month-text--highlighted:hover, .react-datepicker__quarter-text--highlighted:hover, .react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1, .react-datepicker__month-text--highlighted-custom-1, .react-datepicker__quarter-text--highlighted-custom-1, .react-datepicker__year-text--highlighted-custom-1 {
  color: #f0f;
}

.react-datepicker__day--highlighted-custom-2, .react-datepicker__month-text--highlighted-custom-2, .react-datepicker__quarter-text--highlighted-custom-2, .react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  color: #fff;
  background-color: #216ba5;
  border-radius: .3rem;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  color: #fff;
  background-color: #2579ba;
  border-radius: .3rem;
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: #216ba580;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range) {
  color: #000;
  background-color: #f0f0f0;
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover, .react-datepicker__month-text--disabled:hover, .react-datepicker__quarter-text--disabled:hover, .react-datepicker__year-text--disabled:hover {
  background-color: #0000;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  width: 100%;
  display: inline-block;
  position: relative;
}

.react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view {
  border: 1px solid #0000;
  border-radius: .3rem;
  position: relative;
}

.react-datepicker__year-read-view:hover, .react-datepicker__month-read-view:hover, .react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  top: 0;
  right: -16px;
  transform: rotate(135deg);
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
  width: 50%;
  z-index: 1;
  text-align: center;
  background-color: #f0f0f0;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  position: absolute;
  top: 30px;
  left: 25%;
}

.react-datepicker__year-dropdown:hover, .react-datepicker__month-dropdown:hover, .react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable, .react-datepicker__month-dropdown--scrollable, .react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 20px;
  display: block;
}

.react-datepicker__year-option:first-of-type, .react-datepicker__month-option:first-of-type, .react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.react-datepicker__year-option:last-of-type, .react-datepicker__month-option:last-of-type, .react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  user-select: none;
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected, .react-datepicker__month-option--selected, .react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  height: 100%;
  vertical-align: middle;
  background-color: #0000;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  display: table-cell;
  position: absolute;
  top: 0;
  right: 0;
}

.react-datepicker__close-icon:after {
  cursor: pointer;
  color: #fff;
  height: 16px;
  width: 16px;
  text-align: center;
  vertical-align: middle;
  content: "×";
  background-color: #216ba5;
  border-radius: 50%;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  display: table-cell;
}

.react-datepicker__today-button {
  cursor: pointer;
  text-align: center;
  clear: left;
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  padding: 5px 0;
  font-weight: bold;
}

.react-datepicker__portal {
  width: 100vw;
  height: 100vh;
  z-index: 2147483647;
  background-color: #000c;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

._6DhOa_wrapper {
  position: relative;
}

._6DhOa_input {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  position: absolute;
}

._6DhOa_input:focus ~ ._6DhOa_number._6DhOa_hasFocus:after {
  display: block;
}

._6DhOa_input:focus ~ ._6DhOa_number._6DhOa_isLast:after {
  display: block;
  left: 34px;
}

._6DhOa_number {
  width: 56px;
  height: 56px;
  border-radius: var(--border-radius);
  cursor: text;
  background: #fff;
  outline: none;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

._6DhOa_number:after {
  content: "";
  width: 1px;
  height: 20px;
  background: #000;
  animation: 1.25s step-end infinite _6DhOa_cursor-blink;
  display: none;
  position: absolute;
  top: 18px;
  left: 28px;
}

._6DhOa_text {
  font-size: 16px;
  position: relative;
}

._6DhOa_number + ._6DhOa_number {
  margin-left: var(--spacing);
}

@keyframes _6DhOa_cursor-blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

._8UDNZW_wrapper {
  height: 56px;
  padding: 0 calc(var(--spacing) * 2);
  border-radius: var(--border-radius);
  background: #fff;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  display: flex;
}

._8UDNZW_wrapper._8UDNZW_isDisabled {
  cursor: default;
  pointer-events: none;
}

._8UDNZW_wrapper._8UDNZW_isDisabled > * {
  opacity: .2;
}

._8UDNZW_title {
  color: var(--color-black-50);
  font-size: 14px;
  font-weight: 500;
}

._8UDNZW_title._8UDNZW_isChecked {
  color: #000;
}

._9OJw-G_content {
  align-items: center;
  gap: var(--spacing);
  display: flex;
}

._8MVCEq_wrapper {
  align-items: center;
  gap: 10px;
  display: flex;
}

._8MVCEq_icon {
  width: 44px;
}

._8MVCEq_label {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  transition: background-color .2s;
  position: relative;
}

._8MVCEq_label._8MVCEq_focus-visible {
  outline: none;
}

._8MVCEq_label:focus-visible {
  outline: none;
}

._8MVCEq_label._8MVCEq_focus-visible:before, ._8MVCEq_label:focus-visible:before {
  content: "";
  width: 24px;
  height: 24px;
  box-shadow: 0px 0px 0px 2px var(--color-yellow);
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

._8MVCEq_label:after {
  content: "";
  transition: transform .4s;
  transform: scale(0);
}

._8MVCEq_label._8MVCEq_isChecked {
  background-color: var(--color-yellow);
  transition: background-color .2s;
}

._8MVCEq_label._8MVCEq_isChecked:after {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.2L4.29412 6L9 1' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  transition: transform .4s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1);
}

._8MVCEq_pill {
  color: #fff;
  border-radius: var(--border-radius);
  background: #000;
  margin-top: 4px;
  padding: 4px 6px;
  font-size: 11px;
  line-height: 12px;
}

._8MVCEq_pill._8MVCEq_yellow {
  color: #000;
  background: var(--color-yellow);
}

._8MVCEq_right {
  align-items: center;
  gap: 10px;
  display: flex;
}

._8MVCEq_field {
  visibility: hidden;
}

.ewMC6a_options {
  height: 20px;
  background: var(--color-black-10);
  border-radius: var(--border-radius);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  outline: none;
  transition: background-color .1s;
  display: flex;
  position: relative;
}

.ewMC6a_options.ewMC6a_focus-visible:before, .ewMC6a_options:focus-visible:before {
  content: "";
  width: calc(100% + 4px);
  height: 24px;
  box-shadow: 0px 0px 0px 2px var(--color-yellow);
  border-radius: 7px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ewMC6a_options.ewMC6a_isSelected {
  background: var(--color-yellow);
  transition: background-color .1s;
}

.ewMC6a_option {
  width: 25px;
  text-align: center;
  color: #000;
  font-size: 12px;
  line-height: 20px;
  transition: background-color .1s;
}

.ewMC6a_option.ewMC6a_isSelected {
  box-shadow: inset 0 0 0 1px var(--color-border-gray);
  border-radius: var(--border-radius);
  background: #fff;
  transition: background-color .1s;
}

.IcYX3a_alertItem {
  width: 100%;
  text-align: left;
  background: var(--color-yellow);
  position: relative;
  overflow: hidden;
}

.IcYX3a_alertItem > p {
  color: #000;
  padding: 25px 15px 25px 60px;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

@media (min-width: 970px) {
  .IcYX3a_alertItem {
    text-align: center;
    margin-top: 0;
  }

  .IcYX3a_alertItem > p {
    padding: 38px 75px;
    font-size: 16px;
  }
}

.IcYX3a_info {
  width: 30px;
  height: 30px;
  background: url("/app/themes/taxi-stockholm/static/img/info.png") 0 0 / 30px 30px;
  margin-top: -15px;
  position: absolute;
  top: 50%;
  left: 15px;
}

@media (min-width: 970px) {
  .IcYX3a_info {
    left: 35px;
  }
}

.CreBYq_bookingwidget {
  z-index: 40;
  position: relative;
}

.Zu4f4W_info {
  padding: var(--spacing) calc(var(--spacing) * 2);
  color: var(--color-black-50);
  font-size: 13px;
}

.Zu4f4W_info > a {
  color: var(--color-black-50);
}

.Zu4f4W_instructions {
  color: var(--color-black-50);
  font-size: 13px;
}

.Zu4f4W_message {
  padding: calc(var(--spacing) * 2);
}

.-buDlW_error {
  padding: 0 calc(var(--spacing) * 2);
  background: #fff;
  grid-column: 1 / -1;
  margin-top: -4px;
}

.-buDlW_icons {
  gap: 20px;
  margin: 10px 0;
  display: flex;
}

.-buDlW_icon {
  width: 32px;
  height: 32px;
}

.-buDlW_info {
  padding: 0 calc(var(--spacing) * 2);
  grid-column: 1 / -1;
  font-size: 10px;
}

@media (min-width: 970px) {
  ._85QTXq_datepicker {
    height: 100%;
    border-radius: var(--border-radius);
    background: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.XnXoGq_locations {
  gap: var(--spacing);
  flex-direction: column;
  display: flex;
}

.XnXoGq_locations > div {
  z-index: 1;
  position: relative;
}

.XnXoGq_text {
  color: var(--color-black-50);
  margin: calc(var(--spacing) * 2) 0 0;
  font-size: 13px;
}

.an9WHG_loader {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.an9WHG_map {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .5s var(--easeInOutQuad);
}

.an9WHG_isLoaded {
  opacity: 1;
}

.an9WHG_dropAnim {
  animation-name: an9WHG_bounce;
  animation-duration: .7s;
  animation-fill-mode: both;
}

@keyframes an9WHG_bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(30px);
  }

  80% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.AZ0eJq_wrapper {
  color: #000;
  height: 32px;
  align-items: center;
  margin-right: 20px;
  font-size: 13px;
  font-weight: 500;
  display: flex;
}

.AZ0eJq_wrapper.AZ0eJq_notLoggedIn {
  text-decoration: underline;
}

.AZ0eJq_userIcon {
  width: 32px;
  height: 32px;
  background: var(--color-yellow);
  color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  display: flex;
}

.KJPp3G_item {
  background-position: 0 0 !important;
}

.KJPp3G_info {
  font-size: 13px;
  line-height: 1rem;
}

.K4elTG_phoneGroup {
  padding-bottom: 5px !important;
}

.K4elTG_phoneInfo {
  padding: 0 25px 25px;
  font-size: 11px;
  line-height: 1.2em;
}

.K4elTG_wrapper {
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 26px 25px 20px;
  display: flex;
}

.K4elTG_marketingInfo {
  font-size: 11px;
}

.pR4fgW_wrapper {
  width: 624px;
  position: relative;
}

.pR4fgW_overlay {
  visibility: hidden;
  width: 100%;
  height: 100%;
  padding: calc(var(--spacing) * 2);
  transition: background .5s var(--easeOutCirc), visibility 0ms linear .5s;
  z-index: 900;
  pointer-events: none;
  background: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.pR4fgW_overlay.pR4fgW_isOpen {
  transition: background .5s var(--easeOutCirc), visibility 0s linear 0s;
  pointer-events: auto;
  visibility: visible;
  background: #000c;
}

.XU-nOG_body {
  margin-bottom: calc(var(--spacing) * 2);
}

.XU-nOG_wrapper {
  gap: var(--spacing);
  flex-direction: column;
  display: flex;
}

.RzzdwG_wrapper {
  width: 100%;
  height: 100%;
  padding: var(--padding-small);
  z-index: 100;
  background: #00000080;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.RzzdwG_wrapper.RzzdwG_isVisible {
  display: flex;
}

.RzzdwG_container {
  border-radius: var(--border-radius-large);
  background: #fff;
  padding: 30px 0 0;
  position: relative;
  overflow: hidden;
}

.RzzdwG_container.RzzdwG_isCashier {
  width: 100%;
  min-height: 500px;
}

@media (min-width: 768px) {
  .RzzdwG_container {
    max-width: 700px;
    padding: 50px 2px 0;
  }
}

.RzzdwG_form {
  display: none;
}

._1hDY3q_buttons {
  justify-content: start;
  display: flex;
}

@media (min-width: 970px) {
  ._1hDY3q_buttons {
    width: 624px;
    margin-left: auto;
    margin-right: 0;
    margin-right: initial;
    margin-bottom: -1px;
  }
}

._1hDY3q_button {
  color: var(--color-black-50);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  pointer-events: auto;
  background: #bfc0c2;
  padding: 10px 24px;
  font-size: 16px;
  position: relative;
}

._1hDY3q_button._1hDY3q_active {
  color: var(--color-black);
  background: var(--color-cool-gray);
  z-index: 1;
}

._1hDY3q_button._1hDY3q_active:before, ._1hDY3q_button._1hDY3q_active:after {
  display: none;
}

._1hDY3q_button._1hDY3q_active > ._1hDY3q_corner._1hDY3q_left:before {
  box-shadow: 4px 4px 0 0 var(--color-cool-gray);
}

._1hDY3q_button._1hDY3q_active > ._1hDY3q_corner._1hDY3q_right:before {
  box-shadow: -4px 4px 0 0 var(--color-cool-gray);
}

._1hDY3q_button:before, ._1hDY3q_button:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #bfc0c2;
  display: block;
  position: absolute;
  top: 0;
}

._1hDY3q_button:before {
  left: -5px;
}

._1hDY3q_button:after {
  right: -5px;
}

._1hDY3q_button:first-child:before, ._1hDY3q_button:last-child:after {
  display: none;
}

@media (max-width: 969px) {
  ._1hDY3q_button {
    width: 50%;
  }

  ._1hDY3q_button:first-child {
    border-top-left-radius: 0;
  }

  ._1hDY3q_button:last-child {
    border-top-right-radius: 0;
  }
}

._1hDY3q_cornerTabsLeft {
  width: 8px;
  height: 8px;
  background: #bfc0c2;
  position: absolute;
  bottom: -8px;
  left: 0;
}

._1hDY3q_corner {
  width: 8px;
  height: 8px;
  position: absolute;
  bottom: 0;
  overflow: hidden;
}

._1hDY3q_corner:before {
  content: "";
  width: 200%;
  height: 200%;
  border-radius: 50%;
  display: block;
  position: absolute;
  bottom: 0;
}

._1hDY3q_corner._1hDY3q_left {
  left: -8px;
}

._1hDY3q_corner._1hDY3q_left:before {
  right: 0;
  box-shadow: 4px 4px #bfc0c2;
}

._1hDY3q_corner._1hDY3q_right {
  right: -8px;
}

._1hDY3q_corner._1hDY3q_right:before {
  left: 0;
  box-shadow: -4px 4px #bfc0c2;
}

.ievK_G_bookingwidget {
  z-index: 40;
  position: relative;
}

._1W6YBa_info {
  color: var(--color-black);
  margin-top: calc(var(--spacing) * 3);
  font-size: 13px;
  line-height: 1.23em;
}

._1W6YBa_info p {
  font-size: 13px;
  line-height: 1.23em;
}

._1W6YBa_info p + p {
  margin-top: 1.23em;
}

._1W6YBa_info a {
  color: var(--color-black);
}

._1W6YBa_hideOverflow {
  overflow: hidden;
}

.M00oNW_wrapper {
  width: 100%;
  height: 200%;
  pointer-events: none;
  position: fixed;
  bottom: 0;
  overflow: hidden;
}

.M00oNW_wrapper.M00oNW_isOpen .M00oNW_overlay {
  opacity: .25;
  pointer-events: auto;
}

.M00oNW_wrapper.M00oNW_isOpen .M00oNW_panel {
  transform: translateY(0);
}

@media (min-width: 970px) {
  .M00oNW_wrapper {
    border-radius: var(--border-radius-large);
    position: absolute;
  }
}

.M00oNW_overlay {
  width: 100%;
  height: 50%;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s var(--easeInOutCirc);
  background: #000;
  position: absolute;
  bottom: 0;
}

@media (min-width: 768px) {
  .M00oNW_overlay {
    border-radius: var(--border-radius-large);
    position: absolute;
  }
}

.M00oNW_panel {
  width: 100%;
  border-top-left-radius: var(--border-radius-large);
  border-top-right-radius: var(--border-radius-large);
  pointer-events: auto;
  transition: transform .3s var(--easeInOutCirc);
  z-index: 1;
  background: #fff;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  transform: translateY(100%);
}

.M00oNW_panelContent {
  padding: calc(var(--spacing) * 2);
  overflow: hidden;
}

.M00oNW_panelContent.M00oNW_noPadding {
  padding: 0;
}

@media (min-width: 768px) {
  .M00oNW_panelContent {
    border-top-left-radius: var(--border-radius-large);
    border-top-right-radius: var(--border-radius-large);
  }
}

.M00oNW_title {
  text-transform: uppercase;
  margin-left: var(--spacing);
  margin-bottom: var(--spacing);
  font-size: 14px;
  font-weight: 500;
}

.M00oNW_alternateBackground {
  background: var(--color-cool-gray);
}

.mS3upG_subHeader {
  color: #000;
  font-size: 13px;
}

.mS3upG_info {
  padding: var(--spacing) calc(var(--spacing) * 2);
}

.mS3upG_info, .mS3upG_instructions {
  color: var(--color-black-50);
  font-size: 13px;
}

:is(.mS3upG_info, .mS3upG_instructions) > a {
  color: var(--color-black-50);
}

:is(.mS3upG_info, .mS3upG_instructions) > a.mS3upG_focus-visible {
  color: #000;
  outline: none;
}

:is(.mS3upG_info, .mS3upG_instructions) > a:focus-visible {
  color: #000;
  outline: none;
}

@media (pointer: fine) {
  :is(.mS3upG_info, .mS3upG_instructions) > a:hover {
    color: #000;
  }
}

.mS3upG_marginTop {
  margin-top: var(--spacing);
}

.mS3upG_message, .mS3upG_panelPadding {
  padding: calc(var(--spacing) * 2);
}

.mS3upG_panelGray {
  background: var(--color-cool-gray);
}

.mS3upG_button {
  color: var(--color-black-50);
  font-size: 13px;
  text-decoration: underline;
}

.mS3upG_button.mS3upG_focus-visible {
  color: #000;
}

.mS3upG_button:focus-visible {
  color: #000;
}

@media (pointer: fine) {
  .mS3upG_button:hover {
    color: #000;
  }
}

@media (min-width: 970px) {
  .uV84GG_datepicker {
    height: 100%;
    border-radius: var(--border-radius);
    background: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.DeBHyq_locations {
  gap: var(--spacing);
  flex-direction: column;
  display: flex;
}

.DeBHyq_addLocationForm {
  margin-top: var(--spacing);
}

.DeBHyq_addLocationButton {
  margin-top: var(--spacing);
  font-weight: 500;
}

.DeBHyq_addLocationButton.DeBHyq_focus-visible {
  text-decoration: underline;
}

.DeBHyq_addLocationButton:focus-visible {
  text-decoration: underline;
}

.DeBHyq_addLocationButton:before {
  content: "+";
  margin-right: 5px;
  display: inline-block;
}

.DeBHyq_addLocationButton:disabled {
  cursor: default;
}

.DeBHyq_text {
  color: var(--color-black-50);
  margin: calc(var(--spacing) * 2) 0 0;
  font-size: 13px;
}

.DeBHyq_row {
  gap: var(--spacing);
  display: flex;
  position: relative;
}

.DeBHyq_dragging {
  z-index: 2 !important;
}

.DeBHyq_field {
  width: 100%;
  z-index: 1;
  position: relative;
}

.DeBHyq_dragHandle {
  width: 48px;
  height: 56px;
  border-radius: var(--border-radius);
  -webkit-user-select: none;
  user-select: none;
  opacity: .1;
  pointer-events: none;
  z-index: 3;
  background: linear-gradient(90deg, #fff0 0%, #fff 20% 100%);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.DeBHyq_dragHandle.DeBHyq_enabled {
  cursor: grab;
  opacity: 1;
  pointer-events: auto;
}

.DeBHyq_dragHandle.DeBHyq_enabled:hover svg {
  fill-opacity: 1;
}

.DeBHyq_removeButton {
  width: 20px;
  height: 56px;
}

.DeBHyq_removeButton .DeBHyq_removeBackground {
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
}

.DeBHyq_removeButton svg {
  transform: rotate(45deg);
}

.DeBHyq_removeButton:hover .DeBHyq_removeBackground {
  background: var(--color-yellow);
}

.DeBHyq_removeButton:hover .DeBHyq_removeBackground > svg {
  fill-opacity: 1;
}

._65QRaW_wrapper {
  z-index: 2000;
  position: absolute;
  top: 0;
}

._65QRaW_container {
  gap: 10px;
  display: flex;
}

._65QRaW_container > * {
  color: #999;
  font-size: 10px;
}

._65QRaW_container button:hover {
  color: #000;
}

@media (max-width: 767px) {
  ._65QRaW_mapButtons {
    display: none;
  }
}

._65QRaW_title {
  font-weight: 600;
}

.DVFiga_wrapper {
  height: 100%;
  transition: transform .3s var(--easeInOutCirc);
}

.DVFiga_wrapper.DVFiga_isOpen {
  transform: translateX(-100%);
}

.DVFiga_buttonWrapper {
  pointer-events: none;
  transition: transform .3s var(--easeInOutCirc);
  position: absolute;
  top: 14px;
  left: -72px;
}

@media (min-width: 970px) {
  .DVFiga_buttonWrapper {
    top: 30px;
    left: -84px;
  }
}

.DVFiga_buttonWrapper.DVFiga_isOpen {
  transform: translateX(90px);
}

@media (min-width: 970px) {
  .DVFiga_buttonWrapper.DVFiga_isOpen {
    transform: none;
  }
}

.DVFiga_buttonWrapper.DVFiga_isOpen .DVFiga_button {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTY0MTRCOTUyMUREMTFFNzgzRTJDMjQ1ODFCMDBCNEQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTY0MTRCOTYyMUREMTFFNzgzRTJDMjQ1ODFCMDBCNEQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NjQxNEI5MzIxREQxMUU3ODNFMkMyNDU4MUIwMEI0RCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NjQxNEI5NDIxREQxMUU3ODNFMkMyNDU4MUIwMEI0RCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pj9Z60AAAAC8SURBVHjatNZRCsMgDAZg6ZF2Fk16//d6gkLnwGytoMbk3w95qZKvlBISwi+vUjHgkmrPRz4PjlJnKQIgXHsdd0yQq5YXE0T6fbHYHHgw7vRKswsEQHbtRUIiHmwZsWBkRVYwN6LBYMgMgyIjrEUYNbd6mBrZlNBlPFsKKT4d/QM5gbNxiHD9wyDYCJG4MQ0ywxiJmDGyvt0K5kHUWAQgMyzKcpKBs6vFcrsJZeCAFCz3drsEXCDpjrwFGAD2Lep8CrxtcwAAAABJRU5ErkJggg==");
  background-size: 13px 13px;
}

.DVFiga_buttonWrapper.DVFiga_isVisible {
  pointer-events: auto;
}

.DVFiga_buttonWrapper.DVFiga_isVisible .DVFiga_button {
  opacity: 1;
  transform: scale(1);
}

.DVFiga_buttonWrapper .DVFiga_button {
  width: 54px;
  height: 54px;
  transition: transform .3s var(--easeInOutCirc), opacity .3s var(--easeInOutCirc);
  opacity: 0;
  cursor: pointer;
  background-color: var(--color-yellow);
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAATCAYAAAB/TkaLAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAEdJREFUeNpiYMAE/xlIAxjqGck0CB9gxGYgpS79T21X0s5QRhoYyjB0vE9M7JPFp7qhsIiiRiZgHHreHw3TIVSgUL2QBggwAIu9Ps0VYC1hAAAAAElFTkSuQmCC");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 21px 19px;
  border-radius: 100%;
  transform: scale(0);
  box-shadow: 0 0 5px #00000040;
}

.DVFiga_buttonWrapper .DVFiga_tooltip {
  opacity: 0;
  color: #fff;
  white-space: nowrap;
  transition: transform .3s var(--easeInOutCirc), opacity .3s var(--easeInOutCirc);
  pointer-events: none;
  background: #000;
  border-radius: 5px;
  margin-left: -15px;
  padding: 0 15px 0 13px;
  font-size: 12px;
  font-weight: 700;
  line-height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(-125%, -50%);
}

.DVFiga_buttonWrapper .DVFiga_tooltip.DVFiga_show {
  opacity: 1;
  transform: translate(-100%, -50%);
}

.DVFiga_buttonWrapper .DVFiga_tooltip:after {
  content: "";
  height: 0;
  width: 0;
  border: 5px solid #0000;
  border-left-color: #000;
  margin-top: 10px;
  margin-left: 14px;
  position: absolute;
}

.DVFiga_buttonWrapper .DVFiga_num {
  width: 23px;
  height: 23px;
  text-align: center;
  transition: transform .3s var(--easeInOutCirc), opacity .3s var(--easeInOutCirc);
  opacity: 0;
  background: #fff;
  border: 1px solid #0000001a;
  border-radius: 100%;
  font-size: 11px;
  font-weight: 500;
  line-height: 21px;
  position: absolute;
  top: -5px;
  left: -5px;
  transform: scale(0);
}

.DVFiga_buttonWrapper .DVFiga_num.DVFiga_show {
  opacity: 1;
  transform: scale(1);
}

.DVFiga_content {
  padding: 28px var(--padding-small) var(--padding-small);
  width: 100vw;
  height: 100%;
  background: #f4f4f4;
  overflow-y: auto;
}

@media (min-width: 970px) {
  .DVFiga_content {
    width: 488px;
    padding: 35px var(--padding) var(--padding);
  }
}

.DVFiga_header {
  text-align: center;
  margin-bottom: 41px;
  font-size: 22px;
  font-weight: 500;
}

@media (min-width: 970px) {
  .DVFiga_header {
    margin-bottom: 35px;
  }
}

.eA4Q8G_widgetWrapper {
  width: 100%;
  pointer-events: auto;
  background: var(--color-cool-gray);
  border-radius: var(--border-radius-large);
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media (min-width: 970px) {
  .eA4Q8G_widgetWrapper {
    width: 624px;
    margin-left: auto;
    margin-right: 0;
    margin-right: initial;
  }
}

.eA4Q8G_widgetWrapper.eA4Q8G_isLoading {
  pointer-events: none;
}

.eA4Q8G_widgetWrapper.eA4Q8G_tabsLeft {
  border-top-left-radius: 0;
}

.eA4Q8G_content, .eA4Q8G_contentBottom {
  grid-template-columns: 1fr;
  display: grid;
}

@media (min-width: 970px) {
  :is(.eA4Q8G_content, .eA4Q8G_contentBottom) {
    grid-template-columns: 1fr 1fr;
  }
}

.eA4Q8G_group {
  grid-gap: inherit;
  align-content: start;
  gap: inherit;
  width: 100%;
  grid-template-rows: max-content;
  display: grid;
}

.eA4Q8G_content {
  gap: calc(var(--spacing) * 2);
}

.eA4Q8G_contentBottom {
  gap: var(--spacing);
  padding: var(--padding-small);
  background: #fff;
}

.eA4Q8G_contentBottom.eA4Q8G_noBackground {
  background: none;
}

.eA4Q8G_contentBottom.eA4Q8G_largePadding {
  padding: var(--padding);
  gap: calc(var(--spacing) * 2);
}

.eA4Q8G_fullWidth {
  grid-column: 1 / -1;
}

.eA4Q8G_fieldDescription {
  height: 56px;
  align-items: center;
  display: flex;
}

.eA4Q8G_capitalize:first-letter {
  text-transform: capitalize;
}

.eA4Q8G_bold {
  font-weight: 500;
}

.eA4Q8G_label {
  text-transform: uppercase;
  font-size: 13px;
  position: relative;
}

.eA4Q8G_label.eA4Q8G_gray {
  color: var(--color-black-50);
}

.eA4Q8G_title {
  margin-top: 5px;
  font-size: 20px;
}

.eA4Q8G_subtitle {
  color: var(--color-black-50);
  margin-top: 2px;
  font-size: 13px;
  line-height: 16px;
}

.eA4Q8G_smaller {
  font-size: 13px;
}

.eA4Q8G_linkButton {
  text-decoration: underline;
}

.eA4Q8G_option {
  color: #000;
  background: var(--color-yellow);
  border-radius: var(--border-radius);
  padding: 4px 6px;
  font-size: 11px;
  line-height: 12px;
}

.sWWoQW_item {
  border-radius: var(--border-radius-large);
  position: relative;
  overflow: hidden;
}

.sWWoQW_top {
  height: 56px;
  color: #fff;
  border-top-left-radius: var(--border-radius-large);
  border-top-right-radius: var(--border-radius-large);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: #000;
  align-items: center;
  gap: 16px;
  padding: 0 18px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
}

.sWWoQW_bottom {
  color: #000;
  border-bottom-left-radius: var(--border-radius-large);
  border-bottom-right-radius: var(--border-radius-large);
  background: #fff;
  flex-direction: column;
  display: flex;
}

.sWWoQW_bottom.sWWoQW_showBorder {
  border: 1px solid #0000001a;
}

.sWWoQW_bottomClickable {
  cursor: pointer;
}

.sWWoQW_stateIcon {
  width: 32px;
  height: 32px;
  border: 1px solid var(--color-yellow);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 32px 32px;
  border-radius: 50%;
  flex-shrink: 0;
}

.sWWoQW_stateIcon.sWWoQW_prebooked {
  background-image: url("/app/themes/taxi-stockholm/static/img/states/prebooked.png");
}

.sWWoQW_stateIcon.sWWoQW_searching {
  background-image: url("/app/themes/taxi-stockholm/static/img/states/searching.png");
}

.sWWoQW_stateIcon.sWWoQW_cancelled {
  background-image: url("/app/themes/taxi-stockholm/static/img/states/cancelled.png");
}

.sWWoQW_stateIcon.sWWoQW_arriving {
  background-image: url("/app/themes/taxi-stockholm/static/img/states/arriving.png");
}

.sWWoQW_stateIcon.sWWoQW_in-car {
  background-image: url("/app/themes/taxi-stockholm/static/img/states/in-car.png");
}

.sWWoQW_stateIcon.sWWoQW_finished {
  background-image: url("/app/themes/taxi-stockholm/static/img/states/finished.png");
}

.sWWoQW_stateStatus {
  flex-grow: 1;
}

.sWWoQW_taxiInfo {
  text-align: right;
}

.sWWoQW_stopsContainer {
  gap: var(--spacing);
  padding: calc(var(--spacing) * 2) var(--padding);
  flex-direction: column;
  display: flex;
  position: relative;
}

.sWWoQW_stopsContainer:before {
  content: "";
  top: var(--padding);
  left: calc(var(--padding)  + 10px);
  width: 1px;
  height: calc(100% - (var(--padding) * 2) );
  border-left: 1px dotted #a7a8a9;
  display: block;
  position: absolute;
}

.sWWoQW_stops {
  gap: var(--spacing);
  flex-direction: column;
  display: flex;
}

.sWWoQW_stop {
  padding-left: 30px;
  font-size: 13px;
  line-height: 20px;
}

.sWWoQW_intermediate {
  position: relative;
}

.sWWoQW_intermediate:before {
  content: "";
  width: 7px;
  height: 7px;
  background: #a7a8a9;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 7px;
  left: 7px;
}

.sWWoQW_infoSection {
  padding: 0 var(--padding) var(--padding-small);
  color: var(--color-black-50);
  font-size: 13px;
  line-height: 16px;
}

.sWWoQW_row {
  padding: var(--padding-small) 0;
  display: flex;
}

.sWWoQW_row + .sWWoQW_row {
  border-top: 1px solid #0000001a;
}

.sWWoQW_info {
  text-align: right;
  color: #000;
  flex: 1;
}

.sWWoQW_infoParts {
  color: var(--color-black-50);
  font-size: 11px;
  display: block;
}

.sWWoQW_receiptButton {
  text-decoration: underline;
}

.sWWoQW_receiptButton:hover {
  color: var(--color-black-50);
}

.sWWoQW_buttonRow {
  padding: var(--padding-small);
}

.sWWoQW_isClosed {
  display: none;
}

.sWWoQW_options {
  flex: 1;
  justify-content: right;
  gap: 2px;
  display: flex;
}

.sWWoQW_option {
  color: #000;
  background: var(--color-yellow);
  border-radius: var(--border-radius);
  padding: 1px 8px 2px;
  font-size: 11px;
  line-height: 11px;
}

.sWWoQW_capitalize:first-letter {
  text-transform: capitalize;
}

.sWWoQW_error {
  justify-content: center;
  display: flex;
}

.sWWoQW_spacer {
  height: 30px;
}

/*# sourceMappingURL=index.8669b60f.css.map */
